import * as s from "../styles/globalStyles";

const BigSpacer = () => {
    return (
        <s.Container>
            <s.SpacerLarge />
            <s.SpacerLarge />
            <s.SpacerLarge />
            <s.SpacerLarge />
        </s.Container>
    );
}

export default BigSpacer;