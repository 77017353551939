import React from "react";
import * as s from "../styles/globalStyles";
import dunkelBockPicture from "../assets/images/Dunkel Bock.png";
import darkAlePicture from "../assets/images/Dark Ale.png";
import amberLagerPicture from "../assets/images/Amber Lager.png";
import pilsnerPicture from "../assets/images/Pilsner.png";
import blondAlePicture from "../assets/images/Blond Ale.png";
import paleAlePicture from "../assets/images/Pale Ale.png";
import { useMediaQuery } from "react-responsive";

const BeerTypesInfo = () => {
    const isMobile = useMediaQuery({ query: '( max-width: 1000px)' });

    return (
        <s.Container flex={1} style={{ width: isMobile ? "90%" : "60%", marginLeft: isMobile ? "5%" : "20%", backgroundColor: "orange" }} jc={"center"} >
            <s.Container ai={"center"} style={{ width: "80%", marginLeft: "10%" }} >
                <s.SpacerLarge />

                <s.TextTitle>
                    Popular beer types
                </s.TextTitle>

                <s.SpacerLarge />
                <s.SpacerLarge />

                <s.StyledImg alt={"Dark Ale"} src={darkAlePicture} />
                <s.SpacerSmall />
                <s.TextSubTitle>
                    Ale
                </s.TextSubTitle>
                <s.SpacerSmall />
                <s.TextDescription>
                    This is the oldest style of beer, which dates back to the old age.
                    What distinguishes an ale is a warm-temperature fermentation for a relatively short period of time.
                    In the brewing process, top-fermenting yeasts is introduced, which ferment on the top of the brew.
                    The fermentation process turns that into a boozy beverage.
                </s.TextDescription>

                <s.SpacerLarge />
                <s.SpacerLarge />

                <s.StyledImg alt={"Pale Ale"} src={paleAlePicture} />
                <s.SpacerSmall />
                <s.TextSubTitle>
                    Pale Ale
                </s.TextSubTitle>
                <s.SpacerSmall />
                <s.TextDescription>
                    Pale ale is a popular style of beer that's hop-forward with a malty flavor, a golden to amber color, 
                    and moderate strength. Brewed with pale malt and ale yeast, pale ales bridge the gap between 
                    dark stouts and light lagers. They are full of flavor, but not too heavy, so the style is very approachable. 
                    These beers are strong enough to pair well with spicy foods.
                    The original pale ales were created in England, and the style sparked the American craft beer scene. 
                    The style includes malty English ales, balanced American brews, Belgian session ales, and hoppy IPAs.
                </s.TextDescription>

                <s.SpacerLarge />
                <s.SpacerLarge />

                <s.StyledImg alt={"Blond Ale"} src={blondAlePicture} />
                <s.SpacerSmall />
                <s.TextSubTitle>
                    Blonde Ale
                </s.TextSubTitle>
                <s.SpacerSmall />
                <s.TextDescription>
                    This easy drinking ale is a summer favorite, thanks to its light malt sweetness and trace of hops,
                    which add aroma. As the name suggests, blonde ales have a pale color and a clear body.
                    They tend to be crisp and dry, with few traces of bitterness, rather than hop-heavy or dank.
                </s.TextDescription>

                <s.SpacerLarge />
                <s.SpacerLarge />

                <s.StyledImg alt={"Amber Lager"} src={amberLagerPicture} />
                <s.SpacerSmall />
                <s.TextSubTitle>
                    Lager
                </s.TextSubTitle>
                <s.SpacerSmall />
                <s.TextDescription>
                    Lagers are a newer style of beer. Lagers ferment for a long time at a low temperature,
                    and they rely on bottom-fermenting yeasts, which sink to the bottom of the fermenting tank to do their magic.
                    Lager beer uses a process of cool fermentation, followed by maturation in cold storage. The German word "Lager"
                    means storeroom or warehouse. The yeast generally used with lager brewing is Saccharomyces pastorianus.
                    It is a close relative of the Saccharomyces cerevisiae yeast used for warm fermented ales.
                    While prohibited by the German Reinheitsgebot tradition, lagers in some countries often feature large proportions of adjuncts,
                    usually rice or maize. It is possible to use lager yeast in a warm fermentation process, such as with American steam beer;
                    while German Altbier and Kölsch are brewed with Saccharomyces cerevisiae top-fermenting yeast at a warm temperature,
                    but with a cold-storage finishing stage, and classified as obergäriges lagerbier (top-fermented lager beer).
                </s.TextDescription>

                <s.SpacerLarge />
                <s.SpacerLarge />

                <s.StyledImg alt={"Pilsner"} src={pilsnerPicture} />
                <s.SpacerSmall />
                <s.TextSubTitle>
                    Pilsner
                </s.TextSubTitle>
                <s.SpacerSmall />
                <s.TextDescription>
                    Pilsner is a pale lager with a crisp, refreshing taste that's lightly hopped.
                    It was originally brewed in the town of Pilsen (Plzeň) in what is now the Czech Republic.
                    The brewery that would become known as Pilsner Urquell created the beer in 1842,
                    and it was an immediate success. It did not take long for brewers throughout Bohemia
                    to adopt the style. Today, the bottom-fermented beer with lightly kilned malted barley
                    and moderate alcohol is easily the most popular style of beer in the world.
                    It's easy to get pilsner and lager confused, and breweries don't help because some use one name
                    or the other to describe their beer. The key point to remember is that all pilsners are lagers,
                    but pilsner is just one style of beer within the lager family, which includes dark and amber beers as well.
                    Pilsner happens to be the most recognizable and has the signature look and taste of what many beer drinkers expect from a lager.
                </s.TextDescription>

                <s.SpacerLarge />
                <s.SpacerLarge />

                <s.StyledImg alt={"Helles Bock"} src={dunkelBockPicture} />
                <s.SpacerSmall />
                <s.TextSubTitle>
                    Bock
                </s.TextSubTitle>
                <s.SpacerSmall />
                <s.TextDescription>
                    Before getting into its rich and interesting history, let’s first answer the question:
                    <s.SpacerSmall />
                    What is bock beer?
                    <s.SpacerSmall />
                    Bock is a high alcohol content lager made using bottom-fermenting yeast in cold temperatures.
                    Although stronger than the typical lager, most beers of this style are only moderately hopped
                    and have a dominant malt character. Traditional bocks are brewed using Vienna and Munich malts.
                    Small portions of roasted malts add color to the overall brew. Continental European hops,
                    with their mild character,are also used, resulting in a cleaner, rounder, less harsh lager.
                    The process of brewing bock involves a long boiling and mashing period to help bring out the melanoidin
                    and caramel flavors. Fruitiness, like hoppiness, is kept to a minimum. If you would like to try your hand
                    at brewing a bock beer at home, the American Homebrewers Association has an easy recipe that you can check out.
                </s.TextDescription>

                <s.SpacerLarge />
                <s.SpacerLarge />

            </s.Container>
        </s.Container >
    );
}

export default BeerTypesInfo;