import React, { useRef, useState, useEffect } from "react";
import * as s from "./styles/globalStyles";
import "./styles/app.css";
import Header from "./components/Header";
import BigSpacer from "./components/BigSpacer";
import BlockChain from "./components/BlockChain";
import GeneralInfo from "./components/GeneralInfo";
import BeerTypesInfo from "./components/BeerTypesInfo";
import { useMediaQuery } from "react-responsive"

function App() {
  const [showBackToTopButton, setShowButton] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const aboutTheProjectRef = useRef(null);
  const scrollToAboutTheProject = () => aboutTheProjectRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });

  const roadmapRef = useRef(null);
  const scrollToRoadmap = () => roadmapRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });

  const faqRef = useRef(null);
  const scrollToFAQ = () => faqRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });

  const contactsRef = useRef(null);
  const scrollToContacts = () => contactsRef.current.scrollIntoView({ block: 'start',  behavior: 'smooth' });

  return (
    <s.Screen style={{ backgroundColor: "var(--dark-grey)" }} >
      <Header
        scrollToAboutTheProject={scrollToAboutTheProject}
        scrollToRoadmap={scrollToRoadmap}
        scrollToFAQ={scrollToFAQ}
        scrollToContacts={scrollToContacts}
      />

      <s.SpacerLarge />
      <s.SpacerLarge />

      <BigSpacer />

      <BlockChain />

      {showBackToTopButton && (
        <s.Container>
          <button onClick={scrollToTop} className="back-to-top" >
            <i className="fa fa-chevron-up"></i>
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
          </button>
        </s.Container>
      )}

      <GeneralInfo
        aboutTheProjectRef={aboutTheProjectRef}
        roadmapRef={roadmapRef}
        faqRef={faqRef}
      />

      <BeerTypesInfo />

      <s.TextDescription ref={contactsRef} style={{
        width: isMobile ? "70%" : "30%",
        alignSelf: "center",
        textAlign: "center"
      }}
        jc={"center"} >

        <BigSpacer />
        <s.TextTitle style={{"color": "orange"}}>
          Contact us
        </s.TextTitle>
        <s.SpacerSmall />
        Discord Channel: <a href="https://discord.gg/WThypRhbAk" rel="noopener noreferrer" target="_blank">CyberBrewery</a><br/>
        Official Twitter: <a href="https://twitter.com/cyberBrewery" rel="noopener noreferrer" target="_blank">@cyberbrewery</a><br/>
        Email: <a href="mailto: support@cyberbrewerynft.com" rel="noopener noreferrer" target="_blank">support@cyberbrewerynft.com</a>
      </s.TextDescription>

      <BigSpacer />
    </s.Screen >
  );
}

export default App;
