import { connect } from "../redux/blockchain/blockchainActions";
import icon from "../assets/images/Mistery Beer.png";
import * as s from "../styles/globalStyles";
import Contract from "../contracts/CyberBrewery.json";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, fetchPresaleData } from "../redux/data/dataActions";
import { useMediaQuery } from "react-responsive";

const BlockChain = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [feedback, setFeedback] = useState("");
    const [claimingNft, setClaimingNft] = useState(false);
    const [nftQuantity, setQuantity] = useState(1);
    
    function checkQuantity(value) {
        if (value > data.maxMintAmount) {
            setFeedback("Amount of Beers cannot exceed the maximum of " + data.maxMintAmount);
            return;
        }
        setQuantity(value);
    }

    const isDeployed = true;
    const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
    const isXtraSmallMobile = useMediaQuery({ query: '(max-width: 325px)' });

    const getPresaleInfo = () => {
        dispatch(fetchPresaleData(blockchain.account));
    }

    const claimNFTs = (_amount, _cost) => {
        if (_amount <= 0) {
            return;
        }
        setFeedback("Minting your Cyber Beer...");
        setClaimingNft(true);
        blockchain.smartContract.methods
            .mint(blockchain.account, _amount)
            .send({
                gasLimit: Contract.GasLimit,
                to: Contract.Address,
                from: blockchain.account,
                value: (_cost * _amount).toString(),
            })
            .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                setFeedback(
                    "You now own a Cyber Beer. Go visit Opensea.io to view it."
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account))
            setAmountOptions();
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    function setAmountOptions() {
        var arrOptions = [];
        for (var i = 1; i <= data.maxMintAmount; i++) {
            arrOptions.push("<option value='" + i + "'>" + i + "</option>");
        }
        document.getElementById("nft-quantity").innerHTML = arrOptions.join();
    }

    return (
        blockchain.account === "" || blockchain.smartContract === null ? (

            <s.Container flex={1} ai={"center"} jc={"center"}>
                <s.LogoImg alt={"logo"} src={icon} />
                <s.SpacerSmall />
                <s.TextTitle style={{ textAlign: "center" }}>
                    Mint a Cyber Beer
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                    Connect to the Ethereum network
                </s.TextDescription>
                <s.SpacerSmall />
                <s.StyledButton style={{ width: isXtraSmallMobile ? "100px" : isMobile ? "150px" : "200px" }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (isDeployed) {
                            dispatch(connect());
                            getData();
                        }
                    }}
                >
                    CONNECT WALLET
                </s.StyledButton>
                <s.SpacerSmall />
                {blockchain.errorMsg !== "" ? (
                    <s.TextDescription style={{ textAlign: "center" }}>
                        {blockchain.errorMsg}
                    </s.TextDescription>
                ) : null}
            </s.Container>
        ) : (
            <s.Container flex={1} ai={"center"} jc={"center"}>
                <s.TextTitle
                    style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
                >
                    {data.totalSupply}/{data.maxBeers}
                </s.TextTitle>
                <s.SpacerMedium />

                {Number(data.totalSupply) === Number(data.maxBeers) ? (
                    <>
                        <s.TextTitle style={{ textAlign: "center" }}>
                            The sale has ended.
                        </s.TextTitle>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                            You can still find Cyber Breweries on{" "}
                            <a
                                target={"_blank"} rel="noopener noreferrer"
                                href={"https://opensea.io/collection/cyberbrewery"}
                            >
                                Opensea.io
                            </a>
                        </s.TextDescription>
                    </>
                ) : (
                    <>
                        {Boolean(data.isWhitelisted) && !Boolean(data.saleIsActive) ? (
                            <s.TextTitle style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}>
                                I am whitelisted
                            </s.TextTitle>
                        ) : (
                            ""                    
                        )}

                        <s.TextTitle style={{ textAlign: "center" }}>
                            1 Cyber Beer costs {data.cost / 1e18} ETH.
                        </s.TextTitle>
                        <s.SpacerXSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                            Excluding gas fee.
                        </s.TextDescription>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                            {feedback}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <s.TextDescription for="nft-quantity">Amount:</s.TextDescription>
                            <select name="nft-quantity" id="nft-quantity"
                                onChange={e => checkQuantity(e.target.value)}
                                value={nftQuantity}
                            ></select>

                            <s.StyledButton
                                disabled={claimingNft ? 1 : 0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    claimNFTs(nftQuantity, data.cost);
                                }}
                            >
                                {claimingNft ? "BUSY" : "MINT"}
                            </s.StyledButton>
                        </s.Container>
                        <s.SpacerLarge />
                        <s.SpacerLarge />
                        <s.Container
                            jc={"center"}
                            ai={"center"}
                            style={{ width: "70%" }}
                        >
                            <s.TextDescription
                                style={{ textAlign: "center", fontSize: 11 }}
                            >
                                Please make sure you are connected to the right network
                                (Ethereum Mainnet) and the correct address. Please note:
                                Once you make the purchase, you cannot undo this action.
                            </s.TextDescription>
                            <s.SpacerSmall />
                            <s.TextDescription
                                style={{ textAlign: "center", fontSize: 11 }}
                            >
                                We have set the gas limit to {Contract.GasLimit} for the contract to
                                successfully mint your NFT. We recommend that you don't
                                change the gas limit.
                            </s.TextDescription>
                        </s.Container>
                        <s.SpacerSmall />
                    </>
                )}
            </s.Container>
        )
    );
}

export default BlockChain;