// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchPresaleDataSuccess = (payload) => {
  return {
    type: "CHECK_PRESALE_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let cost = await store
        .getState()
        .blockchain.smartContract.methods.cost()
        .call();
      // let isWhitelisted = await store
      // .getState()
      // .blockchain.smartContract.methods.isWhitelisted(account)
      // .call();    
      let saleIsActive = await store
        .getState()
        .blockchain.smartContract.methods.saleIsActive()
        .call();
      let maxBeers = await store
      .getState()
      .blockchain.smartContract.methods.maxBeers()
      .call();
      let maxMintAmount = await store
        .getState()
        .blockchain.smartContract.methods.maxMintAmount()
        .call();
        
      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
          saleIsActive,
          maxBeers,
          maxMintAmount
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};

export const fetchPresaleData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let presaleLimit = 0;
      let presaleCost = 0;      

      let balance = await store
      .getState()
      .blockchain.smartContract.methods.balanceOf(account)
      .call();
      
      dispatch(
        fetchPresaleDataSuccess({
          presaleLimit,
          presaleCost,
          balance,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};