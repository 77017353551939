import styled from "styled-components";

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--dark-grey);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
  @media (max-width: 1000px) {
    height: 4px;
    width: 4px;
  }
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
  @media (max-width: 1000px) {
    height: 8px;
    width: 8px;
  }
  
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
  @media (max-width: 1000px) {
    height: 12px;
    width: 12px;
  }
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
  @media (max-width: 1000px) {
    height: 16px;
    width: 16px;
  }
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  user-select: text;
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: ${({ test }) => (test ? "pink" : "none")};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--white);
  @media (max-width: 1000px) {
    font-size: 13px;
    font-weight: 500;
  }
  @media (max-width: 565px) {
    font-size: 10px;
  }
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 14px;
  @media (max-width: 1000px) {
    font-size: 11px;
  }
  @media (max-width: 565px) {
    font-size: 7px;
  }
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  user-select: text;
  color: var(--white);
  text-align: center;
  font-size: 13px;
  @media (max-width: 1000px) {
    font-size: 9px;
  }
  @media (max-width: 565px) {
    font-size: 7px;
  }
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  user-select: text;
  cursor: pointer;
  font-size: 14px;
  color: white;
  @media (max-width: 1000px) {
    font-size: 10px;
  }
  @media (max-width: 565px) {
    font-size: 6px;
  }
  :active {
    opacity: 0.6;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (max-width: 1000px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 565px) {
    width: 120px;
    height: 120px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const LogoImg = styled.img`
  width: 200px;
  height: 200px;
`;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
