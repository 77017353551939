import React from "react";
import * as s from "../styles/globalStyles";
import { useMediaQuery } from "react-responsive";

const Header = (props) => {
  const { scrollToAboutTheProject, scrollToRoadmap, scrollToFAQ, scrollToContacts } = props;
  const isMobile = useMediaQuery({ query: '( max-width: 1000px)' });
  const isXtraSmallMobile = useMediaQuery({ query: '(max-width: 565px)' });

  return (
    <s.Container style={{
      backgroundColor: "orange",
      minHeight: isMobile ? 40 : 80
    }}
      fd={"row"}
      jc={"center"}
      ai={"center"}>

      <s.SpacerLarge />
      <s.SpacerLarge />

      <s.TextTitle style={{
        fontSize: isXtraSmallMobile ? 8 : isMobile ? 20 : 28,
        fontWeight: "bold",
        flex: isXtraSmallMobile ? 0 : 3
      }}>
        CyberBrewery
      </s.TextTitle>

      <s.Container fd={"row"} jc={"center"} ai={"center"} flex={2}>
        <s.SpacerMedium />

        <s.StyledClickable onClick={scrollToAboutTheProject}>
          Project
        </s.StyledClickable>

        {isMobile ? <s.SpacerXSmall /> : <s.SpacerMedium />}

        <s.StyledClickable onClick={scrollToRoadmap}>
          Roadmap
        </s.StyledClickable>

        {isMobile ? <s.SpacerXSmall /> : <s.SpacerMedium />}

        <s.StyledClickable onClick={scrollToFAQ} >
          FAQ
        </s.StyledClickable>

        {isMobile ? <s.SpacerXSmall /> : <s.SpacerMedium />}

        <s.StyledClickable onClick={scrollToContacts}>
          Contacts
        </s.StyledClickable>
      </s.Container>

      <s.SpacerMedium />

      <s.StyledClickable>
        <a className='social-icon-link discord' href="https://discord.gg/WThypRhbAk" target='_blank' rel="noopener noreferrer"
          aria-label='Discord'>
          <img alt="Discord"
            src="https://static.wixstatic.com/media/c6bd41_2dcd1ce07c474c32afc25d8aaa1a111d~mv2.png/v1/fill/w_25,h_25,al_c,q_85,usm_0.66_1.00_0.01/c6bd41_2dcd1ce07c474c32afc25d8aaa1a111d~mv2.webp" />
        </a>
      </s.StyledClickable>

      <s.SpacerSmall />

      <s.StyledClickable>
        <a className='social-icon-link twitter' href="https://twitter.com/cyberBrewery" target='_blank' rel="noopener noreferrer"
          aria-label='Twitter'>
          <img alt="Twitter"
            src="https://static.wixstatic.com/media/c6bd41_8ed6ac4e9b364cee941cb80e37cb8b28~mv2.png/v1/fill/w_25,h_25,al_c,q_85,usm_0.66_1.00_0.01/c6bd41_8ed6ac4e9b364cee941cb80e37cb8b28~mv2.webp" />
        </a>
      </s.StyledClickable>

      <s.SpacerSmall />

      <s.StyledClickable>
        <a className='social-icon-link opensea' href="https://opensea.io/collection/cyberbrewery" target='_blank'
          rel="noopener noreferrer" aria-label='Opensea'>
          <img alt="Opensea"
            src="https://static.wixstatic.com/media/c6bd41_d75bc4168dad43cfac62082f3ec2c749~mv2.png/v1/fill/w_25,h_25,al_c,q_85,usm_0.66_1.00_0.01/c6bd41_d75bc4168dad43cfac62082f3ec2c749~mv2.webp" />
        </a>
      </s.StyledClickable>
      <s.SpacerLarge />
      <s.SpacerLarge />
    </s.Container >
  );
}

export default Header;