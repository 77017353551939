import React from "react";
import Faq from "react-faq-component";
import * as s from "../styles/globalStyles";
import BigSpacer from "./BigSpacer";
import { useMediaQuery } from "react-responsive";

const data = {
    title: "FAQ",
    rows: [
        {
            title: "What is CyberBrewery?",
            content: `CyberBrewery is a collection of 5,000 generated Brews on the Ethereum blockchain.<br/>
            Each brew is completely unique and some are extremely rare.`,
        },
        {
            title: "When will minting begin?",
            content: `Public launch will begin in Q1 of 2022. <br/>
            Follow us on Twitter and join our Discord to stay updated!`,
        },
        {
            title: "How many Cyber Beers can I mint?",
            content:
                `Awesome choice! <br/>
                Everyone will be able to mint as many Cyber Beers as they want. Each transaction will be limited to
                5 Cyber Beers to give everyone a fair chance in minting.`,
        },
        {
            title: "What will be the price of Cyber Beer?",
            content: `Each CyberBrewery NFT will cost 0.04 ETH + GAS fees.`,
        },
        {
            title: "What is the metaverse?",
            content: `The Metaverse is the World 3.0. The word refers to a shared virtual reality where everything and 
                      anything can be bought and sold just like on the markets, using cryptocurrency.`,
        },
    ],
};

const styles = {
    bgColor: 'dark-grey',
    titleTextColor: "orange",
    rowTitleColor: "orange",
    rowContentColor: 'white',
    arrowColor: "orange"
};

const config = {
    animate: true,
    arrowIcon: "V",
    tabFocus: true
};

const GeneralInfo = (props) => {
    const { aboutTheProjectRef, roadmapRef, faqRef } = props;
    const isMobile = useMediaQuery({ query: '( max-width: 1000px)' });
    const isXtraSmallMobile = useMediaQuery({ query: '(max-width: 565px)' });

    styles.titleTextSize = isXtraSmallMobile ? "10px" : isMobile ? "13px" : "18px";
    styles.rowContentTextSize = isXtraSmallMobile ? 7 : isMobile ? 9 : 13;
    styles.rowTitleTextSize = isXtraSmallMobile ? 10 : isMobile ? 13 : 18;

    return (
        <s.Container
            style={{
                width: isMobile ? "90%" : "60%",
                alignSelf: "center",
                textAlign: "center"
            }}
            jc={"center"} >
            <s.TextDescription ref={aboutTheProjectRef}>
                <BigSpacer />

                <s.TextTitle style={{ color: "orange" }}>
                    About the project
                </s.TextTitle>
                <s.SpacerSmall />
                CyberBrewery is 5,000 randomly generated and unique
                art pieces delicately prepared by experienced brewers.
                Each beer has it's own traits and flavour type.
                <s.SpacerSmall />
                Each Cyber Beer NFT will gain you access and membership
                to the exclusive brewery club.
                <s.SpacerSmall />
                All Cyber Beer NFT tokens will be stored on the Ethereum blockchain
                and will be available on opensea.io once all are minted.
                <s.SpacerSmall />
                Join us on our journey & experience one of
                the fines brews.
            </s.TextDescription>

            <BigSpacer />

            <s.TextDescription ref={roadmapRef}>
                <BigSpacer />

                <s.TextTitle style={{ color: "orange" }} >
                    Roadmap
                </s.TextTitle>
                <s.SpacerSmall />
                Each milestone unlocks when a certain % of Cyber Beers have been minted.
                Additional future developments will be decided and voted upon by the
                CyberBrewery community.
                <s.SpacerSmall />
                <span style={{ color: "orange" }}> 20 % </span> Special giveaways for the brewers.
                < s.SpacerSmall />
                <span style={{ color: "orange" }}> 50 % </span> Official Cyber Brewery merch will be released.
                < s.SpacerSmall />
                <span style={{ color: "orange" }}> 80 % </span> Introduce Cyber Brewery into the metaverse.
                < s.SpacerSmall />
                <span style={{ color: "orange" }}> 100 % </span> Second generation brews inspired by the community <br />will be created,
                designed and developed.
            </s.TextDescription>

            <BigSpacer />
            <BigSpacer />

            <div className="faq-style-wrapper" ref={faqRef}>
                <Faq
                    data={data}
                    styles={styles}
                    config={config}
                />
            </div>

            <BigSpacer />
            <BigSpacer />
        </s.Container >
    );
}

export default GeneralInfo;